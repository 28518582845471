/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/navbar/navbar.component.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./layout/navbar/navbar.component";
import * as i5 from "@gilsdav/ngx-translate-router";
import * as i6 from "./providers/services/store-translate.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./providers/services/contacts.service";
import * as i9 from "./providers/services/catalog.service";
import * as i10 from "@angular/router";
import * as i11 from "./layout/button-go-up/button-go-up.component.ngfactory";
import * as i12 from "./layout/button-go-up/button-go-up.component";
import * as i13 from "./providers/services/utils.service";
import * as i14 from "./layout/footer/footer.component.ngfactory";
import * as i15 from "./layout/footer/footer.component";
import * as i16 from "@angular/forms";
import * as i17 from "./providers/services/files.service";
import * as i18 from "./app.component";
import * as i19 from "./providers/services/pages.service";
import * as i20 from "./providers/services/seo.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵprd(512, null, i3.NgbPopoverConfig, i3.NgbPopoverConfig, []), i1.ɵdid(2, 245760, null, 0, i4.NavbarComponent, [i5.LocalizeRouterService, i6.StoreTranslateService, i7.TranslateService, i8.ContactsService, i3.NgbModal, i3.NgbPopoverConfig, i1.NgZone, i9.CatalogService], null, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-button-go-up", [], null, null, null, i11.View_ButtonGoUpComponent_0, i11.RenderType_ButtonGoUpComponent)), i1.ɵdid(6, 114688, null, 0, i12.ButtonGoUpComponent, [i13.UtilsService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(8, 180224, null, 0, i15.FooterComponent, [i8.ContactsService, i16.FormBuilder, i17.FilesService, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-elsa", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 180224, null, 0, i18.AppComponent, [i13.UtilsService, i19.PagesService, i10.Router, i20.SeoService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-elsa", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
