import {MetaDefinition} from '@angular/platform-browser';
import {environment} from '../environments/environment';

export enum Galleries {
  BannerHomepage = 'HOMEPAGE-SLIDER-1',
  Homepage = 'HOMEPAGE_PORTFOLIO_1'
}

export enum StaticPage {
  AboutUs = 'chi-siamo',
  Elsa = 'elsa-nel-mondo',
  Contacts = 'contatti',
  Homepage = 'homepage',
  Privacy = 'privacy',
  Cookie = 'cookies',
  Products = 'prodotti',
  Product = 'prodotto'
}

export enum Paths {
  Homepage = 'homepage',
  AboutUs = 'chi-siamo',
  Contacts = 'contatti',
  Cookies = 'cookies',
  Elsa = 'elsa-nel-mondo',
  Products = 'tutti-i-prodotti',
  Privacy = 'privacy',
  Product = 'prodotto',
  Kit = 'kit',
  Search = 'ricerca',
  NotFound = 'not-found',
  News = 'news',
  NewsSingle = 'news-single'
}

export const StaticPageContent = {
  [StaticPage.AboutUs]: {
    description: 'descrizione',
    vision: 'la-nostra-visione',
    mission: 'la-nostra-missione',
    values: 'i-nostri-valori',
    italy: 'made-in-italy',
    certifications: 'le-nostre-certificazioni',
    organizationModel: 'modello-organizzativo',
    ethic: 'etica',
    organizationAct: 'atto-organizzativo'
  },
  [StaticPage.Elsa]: {
    reseller: 'diventa-un-rivenditore',
    formInfo: 'form-richiesta-di-informazioni'
  },
  [StaticPage.Contacts]: {
    info: 'informazioni'
  },
  [StaticPage.Privacy]: {
    content: 'informativa'
  },
  [StaticPage.Cookie]: {
    content: 'informativa'
  },
  [StaticPage.Homepage]: {
    kit: 'kit',
    accessories: 'accessori',
    sectors: 'settori-di-applicazione',
    elsa: 'elsa-nel-mondo'
  },
  [StaticPage.Products]: {
    kit: 'section-kit',
    accessories: 'section-accessori',
    comparator: 'comparatore-macchine',
    modalComparator: 'modal-comparatore'
  },
  [StaticPage.Product]: {
    kit: 'section-kit',
    accessories: 'section-accessori'
  }
};

export enum SemiPath {
  AboutUs = 'about_us',
  Contacts = 'contacts',
  Cookies = 'cookies',
  Elsa = 'elsa',
  Products = 'products',
  Privacy = 'privacy',
  Kit = 'kit',
  Search = 'search',
  NotFound = 'not_found',
  News = 'news'
}

export const PathNames = {
  [Paths.Homepage]: 'path_name_homepage',
  [Paths.AboutUs]: 'path_name_about_us',
  [Paths.Contacts]: 'path_name_contacts',
  [Paths.Cookies]: 'path_name_cookies',
  [Paths.Elsa]: 'path_name_elsa',
  [Paths.Products]: 'path_name_products',
  [Paths.Privacy]: 'path_name_privacy',
  [Paths.Kit]: 'path_name_kit',
  [Paths.Search]: 'path_name_search',
  [Paths.NotFound]: 'path_name_not_found',
  [Paths.News]: 'path_name_news',
};

const pathsValue = {
  [Paths.Homepage]: () => ['/'],
  [Paths.AboutUs]: () => [`/${SemiPath.AboutUs}`],
  [Paths.Contacts]: () => [`/${SemiPath.Contacts}`],
  [Paths.Cookies]: () => [`/${SemiPath.Cookies}`],
  [Paths.Elsa]: () => [`/${SemiPath.Elsa}`],
  [Paths.Products]: () => [`/${SemiPath.Products}`],
  [Paths.Product]: (values) => [`/${SemiPath.Products}`, getParam(0, values)],
  [Paths.Privacy]: () => [`/${SemiPath.Privacy}`],
  [Paths.NotFound]: () => [`/${SemiPath.NotFound}`],
  [Paths.Kit]: (values) => [`/${SemiPath.Products}/${SemiPath.Kit}`, getParam(0, values)],
  [Paths.Search]: (values) => [`/${SemiPath.Search}`, getParam(0, values)],
  [Paths.News]: () => [`/${SemiPath.News}`],
  [Paths.NewsSingle]: (values) => [`/${SemiPath.News}`, getParam(0, values)],
};

const translationsEndpoints = {
  languages: () => `${environment.baseUriBE}/translations`,
  language: (lang) => `${environment.baseUriBE}/translations/_${lang}`
};

const galleriesEndpoints = {
  gallery: (code) => `${environment.baseUriBE}/galleries/_${code}`
};

const numbersEndpoints = {
  numbers: () => `${environment.baseUriBE}/numbers`
};

const pressEndpoints = {
  inEvidence: () => `${environment.baseUriBE}/news/highlights`,
  inForeground: () => `${environment.baseUriBE}/news/in-foreground`,
  single: (slug: string) => `${environment.baseUriBE}/news/_${slug}`,
  all: () => `${environment.baseUriBE}/news`,
  related: (slug: string) => `${environment.baseUriBE}/news/_${slug}/related`,
};

const contactsEndpoints = {
  all: () => `${environment.baseUriBE}/contacts`,
  world: () => `${environment.baseUriBE}/contacts/world`
};

const searchEndpoints = {
  search: (term) => `${environment.baseUriBE}/elastic?term=${term}`
};

const teamEndpoints = {
  members: () => `${environment.baseUriBE}/team/members`
};

const catalogEndpoints = {
  allProducts: () => `${environment.baseUriBE}/catalogs/products`,
  applications: () => `${environment.baseUriBE}/catalogs/applications`,
  product: (slug: string) => `${environment.baseUriBE}/catalogs/product/slug/_${slug}`,
  productInEvidence: () => `${environment.baseUriBE}/catalogs/product/in-evidence`,
  series: () => `${environment.baseUriBE}/catalogs/series`,
  serieInEvidence: () => `${environment.baseUriBE}/catalogs/serie/in-evidence`,
  accessoriesCategories: (machine = '') => `${environment.baseUriBE}/catalogs/accessories/categories?machine=${machine}`,
  kitseries: () => `${environment.baseUriBE}/catalogs/kitseries`,
  kitserie: (slug) => `${environment.baseUriBE}/catalogs/kitseries/slug/_${slug}`,
  compareProducts: (firstSlug, secondarySlug) => `${environment.baseUriBE}/catalogs/product/comparison/_${firstSlug}/_${secondarySlug}`
};

const pagesEndpoints = {
  page: (slug) => `${environment.baseUriBE}/pages/contents/_${slug}`,
  seo: (slug) => `${environment.baseUriBE}/pages/seo/_${slug}`,
  histories: () => `${environment.baseUriBE}/pages/histories`,
  applications: () => `${environment.baseUriBE}/pages/sectors`,
};

const requestEndpoints = {
  sendRequest: (type) => `${environment.baseUriBE}/forms/${type}`
};

const certificationsEndpoints = {
  certifications: () => `${environment.baseUriBE}/certifications`
};

const filesEndpoints = {
  file: (slug) => `${environment.baseUriBE}/files/_${slug}`
};

//  La chiave è string perchè deve coincidere con il valore di paths
const seoStatic: { [key: string]: { title: string, tags?: MetaDefinition[] } } = {};

//  E' stato creato il metodo per accettare i valori provenienti dall'html, precisamente dalla pipe
const getParam = (position, values): any => {
  if (!values || !values.length || !(values.length > position)) {
    throw new Error('Errore nel numero di elementi passati');
  }
  return values[position];
};

const getFlagIcon = (lang) => `https://media.elsasrl.com/storage/elsa-platform/media/static/languages/${lang}.png`;

export class Config {
  public static ENDPOINTS = {
    translations: translationsEndpoints,
    galleries: galleriesEndpoints,
    contacts: contactsEndpoints,
    press: pressEndpoints,
    search: searchEndpoints,
    catalog: catalogEndpoints,
    numbers: numbersEndpoints,
    request: requestEndpoints,
    pages: pagesEndpoints,
    team: teamEndpoints,
    certifications: certificationsEndpoints,
    files: filesEndpoints
  };
  public static PATHS_VALUE = pathsValue;
  public static COOKIE_KEYS = {lang: 'Language', bearer: 'access-token', user: 'User'};
  public static QUERY_PARAMS_API = {page: 'page'};
  public static getFlagIcon = getFlagIcon;
  public static ELSA_VALIDATION_FORMS_STATUS_CODE = 460;
  public static ELSA_VALIDATION_FORMS_KEY = 'elsa_invalid';
  public static STATIC_FILES = {
    caseStudy: 'case-studies',
    catalog: 'catalog',
    ethic: 'codice-etico',
    organizationAct: 'atto-organizzativo',
    organizationModel: 'modello-organizzativo'
  };
}

export const PhonePrefixes = ['+ 1', '+ 1 242', '+ 1 246', '+ 1 264', '+ 1 268', '+ 1 284', '+ 1 340', '+ 1 345',
  '+ 1 441', '+ 1 473', '+ 1 649', '+ 1 664', '+ 1 758', '+ 1 767', '+ 1 784', '+ 1 787', '+ 1 868', '+ 1 869', '+ 1 876',
  '+ 20', '+ 212', '+ 213', '+ 216', '+ 218', '+ 220', '+ 221', '+ 222', '+ 223', '+ 224', '+ 225', '+ 226', '+ 228', '+ 229',
  '+ 230', '+ 231', '+ 232', '+ 233', '+ 234', '+ 235', '+ 236', '+ 237', '+ 238', '+ 239', '+ 240', '+ 241', '+ 242', '+ 243',
  '+ 244', '+ 245', '+ 246', '+ 247', '+ 248', '+ 249', '+ 250', '+ 251', '+ 252', '+ 253', '+ 254', '+ 255', '+ 256', '+ 257',
  '+ 258', '+ 260', '+ 261', '+ 262', '+ 263', '+ 264', '+ 265', '+ 266', '+ 267', '+ 268', '+ 269', '+ 27', '+ 290', '+ 291',
  '+ 297', '+ 298', '+ 299', '+ 30', '+ 31', '+ 32', '+ 33', '+ 34', '+ 350', '+ 351', '+ 352', '+ 353', '+ 354', '+ 355',
  '+ 356', '+ 357', '+ 358', '+ 359', '+ 36', '+ 370', '+ 371', '+ 372', '+ 373', '+ 374', '+ 375', '+ 376', '+ 377', '+ 378',
  '+ 380', '+ 381', '+ 382', '+ 385', '+ 386', '+ 387', '+ 389', '+ 39', '+ 40', '+ 41', '+ 420', '+ 421', '+ 423', '+ 43',
  '+ 44', '+ 45', '+ 46', '+ 47', '+ 48', '+ 49', '+ 500', '+ 501', '+ 502', '+ 503', '+ 504', '+ 505', '+ 506', '+ 507',
  '+ 508', '+ 509', '+ 51', '+ 52', '+ 53', '+ 54', '+ 55', '+ 56', '+ 57', '+ 58', '+ 590', '+ 591', '+ 592', '+ 593',
  '+ 594', '+ 595', '+ 596', '+ 598', '+ 599', '+ 60', '+ 61', '+ 62', '+ 63', '+ 64', '+ 65', '+ 66', '+ 670', '+ 671', '+ 672',
  '+ 67210-1-2', '+ 673', '+ 675', '+ 676', '+ 677', '+ 678', '+ 679', '+ 680', '+ 681', '+ 682', '+ 683', '+ 684', '+ 685',
  '+ 686', '+ 687', '+ 688', '+ 689', '+ 692', '+ 7', '+ 737', '+ 81', '+ 82', '+ 84', '+ 850', '+ 852', '+ 853', '+ 855',
  '+ 856', '+ 86', '+ 880', '+ 886', '+ 90', '+ 91', '+ 92', '+ 93', '+ 94', '+ 95', '+ 960', '+ 961', '+ 962', '+ 963', '+ 964',
  '+ 965', '+ 966', '+ 967', '+ 968', '+ 971', '+ 972', '+ 973', '+ 974', '+ 975', '+ 976', '+ 977', '+ 98', '+ 993', '+ 994',
  '+ 995', '+ 996', '+ 996', '+ 998'];

export const NationsList = [
  {value: 'AFGHANISTAN', label: 'Afghanistan'},
  {value: 'ALBANIA', label: 'Albania'},
  {value: 'ALGERIA', label: 'Algeria'},
  {value: 'ANDORRA', label: 'Andorra'},
  {value: 'ANGOLA', label: 'Angola'},
  {value: 'ANGUILLA', label: 'Anguilla'},
  {value: 'ANTIGUA E BARBUDA', label: 'Antigua and Barbuda'},
  {value: 'ARGENTINA', label: 'Argentina'},
  {value: 'ARMENIA', label: 'Armenia'},
  {value: 'ARUBA', label: 'Aruba'},
  {value: 'AUSTRALIA', label: 'Australia'},
  {value: 'AUSTRIA', label: 'Austria'},
  {value: 'AZERBAIGIAN', label: 'Azerbaijan'},
  {value: 'BAHAMAS', label: 'Bahamas'},
  {value: 'BAHRAIN', label: 'Bahrain'},
  {value: 'BANGLADESH', label: 'Bangladesh'},
  {value: 'BARBADOS', label: 'Barbados'},
  {value: 'BELGIO', label: 'Belgium'},
  {value: 'BELIZE', label: 'Belize'},
  {value: 'BENIN', label: 'Benin'},
  {value: 'BERMUDA', label: 'Bermuda'},
  {value: 'BHUTAN', label: 'Bhutan'},
  {value: 'BIELORUSSIA', label: 'Belarus'},
  {value: 'BOLIVIA', label: 'Bolivia'},
  {value: 'BOSNIA ED HERZEGOVINA', label: 'Bosna i Hercegovina'},
  {value: 'BOTSWANA', label: 'Botswana'},
  {value: 'BRASILE', label: 'Brasil'},
  {value: 'TERRITORI BRITANNICI DELL\'OCEANO INDIANO', label: 'British Indian Ocean Territory'},
  {value: 'ISOLE VERGINI BRITANNICHE', label: 'British Virgin Islands'},
  {value: 'BRUNEI DARUSSALAM', label: 'Brunei'},
  {value: 'BULGARIA', label: 'Bulgaria'},
  {value: 'BURKINA FASO', label: 'Burkina Faso'},
  {value: 'BURUNDI', label: 'Burundi'},
  {value: 'CAMBOGIA', label: 'Cambodia'},
  {value: 'ISOLE CAYMAN', label: 'Cayman Islands'},
  {value: 'ISOLA DI NATALE', label: 'Christmas Island'},
  {value: 'ISOLE COCOS', label: 'Cocos Islands'},
  {value: 'ISOLE COOK', label: 'Cook Islands'},
  {value: 'CAMERUN', label: 'Cameroon'},
  {value: 'CANADA', label: 'Canada'},
  {value: 'CAPO VERDE', label: 'Cabo Verde'},
  {value: 'REPUBBLICA CECA', label: 'Česká republika'},
  {value: 'CHILE', label: 'Chile'},
  {value: 'CHINA', label: 'China'},
  {value: 'CIPRO', label: 'Cyprus'},
  {value: 'CITTÀ DEL VATICANO', label: 'Città del Vaticano'},
  {value: 'COLOMBIA', label: 'Colombia'},
  {value: 'COMORE', label: 'Comoros'},
  {value: 'COSTA D\'AVORIO', label: 'Côte d\'Ivoire'},
  {value: 'COSTA RICA', label: 'Costa Rica'},
  {value: 'CROATIA', label: 'Croatia'},
  {value: 'CUBA', label: 'Cuba'},
  {value: 'DANIMARCA', label: 'Danmark'},
  {value: 'GIBUTI', label: 'Djibouti'},
  {value: 'DOMINICA', label: 'Dominica'},
  {value: 'ANTILLE OLANDESI', label: 'Dutch Antilles'},
  {value: 'ECUADOR', label: 'Ecuador'},
  {value: 'EGITTO', label: 'Egypt'},
  {value: 'EL SALVADOR', label: 'El Salvador'},
  {value: 'ERITREA', label: 'Eritrea'},
  {value: 'SPAGNA', label: 'España'},
  {value: 'ESTONIA', label: 'Estonia'},
  {value: 'ETIOPIA', label: 'Ethiopia'},
  {value: 'STATI FEDERATI DELLA MICRONESIA', label: 'Federated States of Micronesia'},
  {value: 'FIJI', label: 'Fiji'},
  {value: 'FINLANDIA', label: 'Finland'},
  {value: 'FRANCIA', label: 'France'},
  {value: 'FRENCH POLYNESIA', label: 'French Polynesia'},
  {value: 'TERRITORIO ANTARTICO FRANCESE', label: 'French Southern and Antarctic Lands'},
  {value: 'ISOLE FALKLAND', label: 'Falkland Islands'},
  {value: 'ISOLE FAROE', label: 'Faroe Islands'},
  {value: 'GABON', label: 'Gabon'},
  {value: 'GAMBIA', label: 'Gambia'},
  {value: 'GEORGIA', label: 'Georgia'},
  {value: 'GERMANIA', label: 'Germany'},
  {value: 'GHANA', label: 'Ghana'},
  {value: 'GIBILTERRA', label: 'Gibraltar'},
  {value: 'GRECIA', label: 'Greece'},
  {value: 'GRENADA', label: 'Grenada'},
  {value: 'GROENLANDIA', label: 'Greenland'},
  {value: 'GUAM', label: 'Guam'},
  {value: 'GUATEMALA', label: 'Guatemala'},
  {value: 'GUADALUPA', label: 'Guadeloupe'},
  {value: 'GUINEA', label: 'Guinea'},
  {value: 'GUINEA-BISSAU', label: 'Guiné-Bissau'},
  {value: 'GUINEA EQUATORIALE', label: 'Guinea Ecuatorial'},
  {value: 'GUYANA FRANCESE', label: 'Guyane française'},
  {value: 'GUYANA', label: 'Guyana'},
  {value: 'HAITI', label: 'Haïti'},
  {value: 'ISOLA HEARD E ISOLE MCDONALD', label: 'Heard Island and McDonald Islands'},
  {value: 'HONDURAS', label: 'Honduras'},
  {value: 'HONG KONG', label: 'Hong Kong'},
  {value: 'UNGHERIA', label: 'Hungary'},
  {value: 'INDIA', label: 'India'},
  {value: 'INDONESIA', label: 'Indonesia'},
  {value: 'IRAN', label: 'Iran'},
  {value: 'IRAQ', label: 'Iraq'},
  {value: 'IRLANDA', label: 'Ireland'},
  {value: 'ISLANDA', label: 'Ísland'},
  {value: 'ISRAELE', label: 'Israel'},
  {value: 'ITALIA', label: 'Italia'},
  {value: 'JAMAICA', label: 'Jamaica'},
  {value: 'GIAPPONE', label: 'Japan'},
  {value: 'GIORDANIA', label: 'Jordan'},
  {value: 'REPUBBLICA DEL KAZAKISTAN', label: 'Kazakhstan Republic'},
  {value: 'KENYA', label: 'Kenya'},
  {value: 'KIRIBATI', label: 'Kiribati'},
  {value: 'KIRGHIZISTAN', label: 'Kyrgyzstan'},
  {value: 'KUWAIT', label: 'Kuwait'},
  {value: 'LAOS', label: 'Laos'},
  {value: 'LESOTHO', label: 'Lesotho'},
  {value: 'LETTONIA', label: 'Latvija'},
  {value: 'LIBANO', label: 'Lebanon'},
  {value: 'LIBERIA', label: 'Liberia'},
  {value: 'LIBIA', label: 'Libya'},
  {value: 'LIECHTENSTEIN', label: 'Liechtenstein'},
  {value: 'LITUANIA', label: 'Lithuania'},
  {value: 'LUSSEMBURGO', label: 'Luxembourg'},
  {value: 'MACAO', label: 'Macao'},
  {value: 'MADAGASCAR', label: 'Madagascar'},
  {value: 'MALAWI', label: 'Malawi'},
  {value: 'MALDIVE', label: 'Maldives'},
  {value: 'MALESIA', label: 'Malaysia'},
  {value: 'MALI', label: 'Mali'},
  {value: 'MALTA', label: 'Malta'},
  {value: 'MARTINIQUE', label: 'Martinique'},
  {value: 'ISOLE MARSHALL', label: 'Marshall Islands'},
  {value: 'MAURITANIA', label: 'Mauritania'},
  {value: 'MAURITIUS', label: 'Mauritius'},
  {value: 'MAYNMAR', label: 'Myanmar'},
  {value: 'MAYOTTE', label: 'Mayotte'},
  {value: 'MESSICO', label: 'México'},
  {value: 'MOLDAVIA', label: 'Moldova'},
  {value: 'MONACO (PRINCIPATO)', label: 'Monaco - Principauté de Monaco'},
  {value: 'MONGOLIA', label: 'Mongolia'},
  {value: 'MONTSERRAT', label: 'Montserrat'},
  {value: 'MAROCCO', label: 'Morocco'},
  {value: 'MOZAMBIQUE', label: 'Moçambique'},
  {value: 'NAMIBIA', label: 'Namibia'},
  {value: 'NAURU', label: 'Nauru'},
  {value: 'NEPAL', label: 'Nepal'},
  {value: 'PAESI BASSI ( OLANDA )', label: 'Netherlands'},
  {value: 'NICARAGUA', label: 'Nicaragua'},
  {value: 'NIGER', label: 'Niger'},
  {value: 'NIGERIA', label: 'Nigeria'},
  {value: 'NIUE', label: 'Niue'},
  {value: 'NORVEGIA', label: 'Norge'},
  {value: 'NOUVELLE CALEDONIE', label: 'Nouvelle-Calédonie'},
  {value: 'ISOLA NORFOLK', label: 'Norfolk Island'},
  {value: 'ISOLE MARIANNE SETTENTRIONALI', label: 'Northern Mariana Islands'},
  {value: 'COREA DEL NORD', label: 'North Korea'},
  {value: 'MACEDONIA', label: 'North Macedonia'},
  {value: 'NUOVA ZELANDA', label: 'New Zealand'},
  {value: 'TERRITORI PALESTINESI OCCUPATI', label: 'Occupied Palestinian Territory'},
  {value: 'OMAN', label: 'Oman'},
  {value: 'PALAU', label: 'Palau'},
  {value: 'PAKISTAN', label: 'Pakistan'},
  {value: 'PANAMA', label: 'Panamá'},
  {value: 'PAPUA NUOVA GUINEA', label: 'Papua New Guinea'},
  {value: 'PARAGUAY', label: 'Paraguay'},
  {value: 'ISOLE AMERICA DEL PACIFICO', label: 'Pacific Islands'},
  {value: 'PERU\'', label: 'Perú'},
  {value: 'PHILIPPINES', label: 'Philippines'},
  {value: 'PITCAIRN', label: 'Pitcairn'},
  {value: 'POLONIA', label: 'Polska'},
  {value: 'PORTOGALLO', label: 'Portugal'},
  {value: 'PORTO RICO', label: 'Puerto Rico'},
  {value: 'QATAR', label: 'Qatar'},
  {value: 'REPUBBLICA CENTROAFRICANA', label: 'République centrafricaine'},
  {value: 'REPUBBLICA DEL CONGO', label: 'République du Congo'},
  {value: 'REPUBBLICA DEMOCRATICA DEL CONGO', label: 'République démocratique du Congo'},
  {value: 'REPUBBLICA DOMINICANA', label: 'República Dominicana'},
  {value: 'REUNION', label: 'Réunion'},
  {value: 'ROMANIA', label: 'România'},
  {value: 'FEDERAZIONE RUSSA', label: 'Russia'},
  {value: 'RUANDA', label: 'Rwanda'},
  {value: 'SAINT KITTS E NEVIS', label: 'Saint Kitts and Nevis'},
  {value: 'SAINT PIERRE E MIQUELON', label: 'Saint Pierre and Miquelon'},
  {value: 'SAINT VINCENT E GRENADINE', label: 'Saint Vincent and the Grenadines'},
  {value: 'SAMOA', label: 'Samoa'},
  {value: 'SAMOA AMERICANE', label: 'Samoa (American)'},
  {value: 'SANT\'ELENA', label: 'Saint Helena'},
  {value: 'SAN MARINO', label: 'San Marino'},
  {value: 'SANTA LUCIA', label: 'Saint Lucia'},
  {value: 'SAO TOME E PRINCIPE', label: 'São Tomé e Príncipe'},
  {value: 'SAUDI ARABIA', label: 'Saudi Arabia'},
  {value: 'SENEGAL', label: 'Sénégal'},
  {value: 'SERBIA E MONTENEGRO', label: 'Serbia and Montenegro'},
  {value: 'SEYCHELLES', label: 'Seychelles'},
  {value: 'SIERRA LEONE', label: 'Sierra Leone'},
  {value: 'SINGAPORE', label: 'Singapore'},
  {value: 'SIRIA', label: 'Syria'},
  {value: 'SLOVACCHIA', label: 'Slovakia'},
  {value: 'SLOVENIA', label: 'Slovenija'},
  {value: 'ISOLE SOLOMON', label: 'Solomon Islands'},
  {value: 'SOMALIA', label: 'Soomaaliya'},
  {value: 'SRI LANKA', label: 'Sri Lanka'},
  {value: 'SUD AFRICA', label: 'South Africa'},
  {value: 'SUD GEORGIA E ISOLE SANDWICH', label: 'South Georgia and the South Sandwich Islands'},
  {value: 'COREA DEL SUD', label: 'South Korea'},
  {value: 'SUDAN', label: 'Sudan'},
  {value: 'SURINAM', label: 'Suriname'},
  {value: 'SVALBARD E JAN MAYEN', label: 'Svalbard and Jan Mayen'},
  {value: 'SVEZIA', label: 'Sweden'},
  {value: 'SVIZZERA', label: 'Switzerland'},
  {value: 'SWAZILAND', label: 'Swaziland'},
  {value: 'TAGIKISTAN', label: 'Tajikistan'},
  {value: 'TAIWAN', label: 'Taiwan'},
  {value: 'TANZANIA', label: 'Tanzania'},
  {value: 'TCHAD', label: 'Tchad'},
  {value: 'THAILANDIA', label: 'Thailand'},
  {value: 'TIMOR EST', label: 'Timor-Leste'},
  {value: 'TOGO', label: 'Togo'},
  {value: 'TOKELAU', label: 'Tokelau'},
  {value: 'TONGA', label: 'Tonga'},
  {value: 'TRINIDAD E TOBAGO', label: 'Trinidad and Tobago'},
  {value: 'TUNISIA', label: 'Tunisia'},
  {value: 'TURCHIA', label: 'Türkiye'},
  {value: 'TURKMENISTAN', label: 'Türkmenistan'},
  {value: 'ISOLE TURKS E CAICOS', label: 'Turks and Caicos Islands'},
  {value: 'TUVALU', label: 'Tuvalu'},
  {value: 'EMIRATI ARABI UNITI', label: 'UAE'},
  {value: 'GRAN BRETAGNA', label: 'United Kingdom'},
  {value: 'U.S.A.', label: 'United States of America'},
  {value: 'ISOLE VERGINI AMERICANE', label: 'U.S. Virgin Islands'},
  {value: 'UCRAINA', label: 'Ukraine'},
  {value: 'UGANDA', label: 'Uganda'},
  {value: 'URUGUAY', label: 'Uruguay'},
  {value: 'UZBEKISTAN', label: 'Uzbekistan'},
  {value: 'VANUATU', label: 'Vanuatu'},
  {value: 'VENEZUELA', label: 'Venezuela'},
  {value: 'VIETNAM', label: 'Vietnam'},
  {value: 'WALLIS E FUTUNA', label: 'Wallis and Futuna'},
  {value: 'YEMEN', label: 'Yemen'},
  {value: 'ZAMBIA', label: 'Zambia'},
  {value: 'ZIMBABWE', label: 'Zimbabwe'}
];
