import { Models } from '../../components/modals/quotation/quotation.component';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@ngx-translate/core";
export class CrmService {
    constructor(apiService, translateService) {
        this.apiService = apiService;
        this.translateService = translateService;
    }
    buildContactDataToSend(name, surname, subject, emailAddress, telephone, requestDescription, privacyConsent, marketingConsent) {
        const dataToSend = {
            __vtrftk: 'sid:267f7288c407d59182ee551fc7609e414186f8ab,1696498117',
            publicid: 'b789ed3964360f609ac0e429cdbf4f05',
            urlencodeenable: '1',
            name: 'Richiesta Informazioni',
            cf_1030: 'Richiesta Informazioni',
            firstname: name,
            lastname: surname,
            cf_998: subject,
            email: emailAddress,
            phone: telephone,
            description: requestDescription
        };
        return this.buildDataToSend(dataToSend, privacyConsent, marketingConsent);
    }
    buildResellerRequestToSend(companyName, address, city, nation, name, surname, emailAddress, telephone, privacyConsent, marketingConsent) {
        const dataToSend = {
            __vtrftk: 'sid:c85ac7134a6127e70b1cd4d2a42220f2c3797b25,1696498003',
            publicid: 'ea5de4a4ab1cf8eca2a6aaa6b7b4bef2',
            urlencodeenable: '1',
            name: 'Diventa Rivenditore',
            cf_1030: 'Diventa Partner Elsa',
            company: companyName,
            firstname: name,
            lastname: surname,
            email: emailAddress,
            cf_916: address,
            city: city,
            cf_902: nation,
            phone: telephone
        };
        return this.buildDataToSend(dataToSend, privacyConsent, marketingConsent);
    }
    buildQuotationRequestToSend(productType, companyName, name, surname, emailAddress, telephone, cityName, capCode, country, targetModel, requestDescription, privacyConsent, marketingConsent) {
        const quoteType = (productType === Models.PRODUCT ? 'Supercombinata'
            : (productType === Models.KIT ? 'Kit Extra' : 'Accessori'));
        const dataToSend = {
            __vtrftk: 'sid:44662c7987a2cddd4d116aab780f80fb5909d6e3,1696498287',
            publicid: '0f30d5a5a73eb9ae7df2a171762f5f6f',
            urlencodeenable: '1',
            name: 'Richiesta Quotazione',
            cf_1030: 'Richiesta Quotazione',
            company: companyName,
            firstname: name,
            lastname: surname,
            email: emailAddress,
            phone: telephone,
            city: cityName,
            code: capCode,
            cf_902: country,
            cf_994: quoteType,
            cf_1000: `${targetModel}`,
            description: requestDescription
        };
        return this.buildDataToSend(dataToSend, privacyConsent, marketingConsent);
    }
    buildDataToSend(dataToSend, privacyConsent, marketingConsent) {
        const dataForCrm = [];
        Object.keys(dataToSend).forEach(key => {
            dataForCrm.push([key, dataToSend[key]]);
        });
        const currentLang = this.translateService.currentLang ? this.translateService.currentLang : 'it';
        dataForCrm.push(['cf_1014', currentLang]);
        dataForCrm.push(['cf_954', this.getLanguageValue(currentLang)]);
        dataForCrm.push(['leadsource', 'Form - Sito Web']);
        if (privacyConsent) {
            dataForCrm.push(['PRIVACY', 'Privacy']);
            dataForCrm.push(['cf_990[]', 'Preso visione della Privacy Policy e consenso al trattamento dei dati per le finalità descritte']);
        }
        if (marketingConsent) {
            dataForCrm.push(['Newsletter', 'Newsletter']);
            dataForCrm.push(['cf_1004', 'SI']);
        }
        return dataForCrm;
    }
    getLanguageValue(currentLang) {
        switch (currentLang) {
            case 'fr':
                return 'Francese';
            case 'en':
                return 'Inglese';
            case 'de':
                return 'Tedesco';
            case 'es':
                return 'Spagnolo';
            case 'pl':
                return 'Polacco';
            case 'ru':
                return 'Russo';
            case 'it':
            default:
                return 'Italiano';
        }
    }
}
CrmService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CrmService_Factory() { return new CrmService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.TranslateService)); }, token: CrmService, providedIn: "root" });
