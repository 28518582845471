import { of, Subscription } from 'rxjs';
import { Config } from '../../../config/config';
import { catchError, concatMap, filter, first, take } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@angular/router";
import * as i3 from "./analytics.service";
export class FormService {
    constructor(apiService, router, analyticsService) {
        this.apiService = apiService;
        this.router = router;
        this.analyticsService = analyticsService;
    }
    sendRequest(type, formGroup, additionalData) {
        return of(true).pipe(concatMap(() => {
            console.log(additionalData);
            console.log(formGroup.value);
            const body = additionalData ? Object.assign({}, formGroup.value, { additionalData }) : formGroup.value;
            console.log(body);
            return this.apiService.post(Config.ENDPOINTS.request.sendRequest(type), body);
        }), first(value => {
            this.analyticsService.formSubmission(type);
            return value;
        }), catchError(err => {
            if (err.status === Config.ELSA_VALIDATION_FORMS_STATUS_CODE) {
                this.setErrorsOnForm(formGroup, err.error);
            }
            throw err;
        }));
    }
    setErrorsOnForm(formGroup, errors) {
        const subscriptions = new Subscription();
        Object.keys(errors).forEach(controlName => {
            const control = formGroup.get(controlName);
            control.setErrors({ [Config.ELSA_VALIDATION_FORMS_KEY]: errors[controlName] });
            subscriptions.add(control.valueChanges.pipe(take(1)).subscribe(() => control.setErrors(null)));
        });
        this.router.events.pipe(filter(e => e instanceof NavigationEnd), take(1)).subscribe(() => subscriptions.unsubscribe());
    }
}
FormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormService_Factory() { return new FormService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AnalyticsService)); }, token: FormService, providedIn: "root" });
export var Forms;
(function (Forms) {
    Forms["CONTACTS"] = "contacts";
    Forms["QUOTATION"] = "quotations";
    Forms["RESELLER"] = "resellers";
})(Forms || (Forms = {}));
