import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {forkJoin, Subscription} from 'rxjs';
import {CatalogService} from '../../../providers/services/catalog.service';
import {Forms, FormService} from '../../../providers/services/form.service';
import {ToasterService} from '../../../providers/services/toaster.service';
import {ApiService} from '../../../providers/services/api.service';
import {map} from 'rxjs/operators';
import {Paths, PhonePrefixes, NationsList} from '../../../../config/config';
import {CrmService} from '../../../providers/services/crm.service';
import {ConsentService} from '../../../providers/services/consent.service';
import {CrmFormHelper} from '../../../helpers/crm-form-helper';

export enum Models {
  PRODUCT = 'Product',
  KIT = 'Kitserie',
  ACCESSORIES = 'Accessories'
}

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit, OnDestroy {

  public readonly Paths = Paths;

  set product(value) {
    this.formGroup.get('model_slug').setValue(value);
    this.formGroup.get('model_name').setValue(Models.PRODUCT, {emitEvent: false});
  }

  set kit(value) {
    this.formGroup.get('model_slug').setValue(value);
    this.formGroup.get('model_name').setValue(Models.KIT, {emitEvent: false});
  }

  set accessoryCategory(value) {
    console.log(value);
    this.formGroup.get('model_slug').setValue(value);
    this.formGroup.get('model_name').setValue(Models.ACCESSORIES, {emitEvent: false});
  }

  formGroup: FormGroup;
  products;
  series;
  accessories;

  private readonly subscriptions = new Subscription();
  public readonly Models = Models;
  public readonly PhonePrefixes = PhonePrefixes;
  public readonly NationsList = NationsList;

  constructor(
    public modalActive: NgbActiveModal, private fb: FormBuilder, private catalogService: CatalogService,
    private formService: FormService, private alertService: ToasterService, private apiService: ApiService,
    private crmService: CrmService, private consentService: ConsentService
  ) {
    this.formGroup = this.fb.group({
      company_name: '',
      name: '',
      surname: '',
      email: '',
      phone_prefix: '',
      phone: '',
      address: '',
      city: '',
      cap: '',
      country: '',
      model_slug: '',
      model_name: '',
      text: '',
      privacy: false,
      marketing: false
    });
    this.subscriptions.add(
      this.formGroup.get('model_name').valueChanges.subscribe(() => this.formGroup.get('model_slug').setValue(''))
    );
    this.subscriptions.add(
      this.apiService.translateApi(
        locale => forkJoin([
          this.catalogService.getProductsWithLocale(locale).pipe(map(({body}) => this.products = body ? body.data.products : null)),
          this.catalogService.getKitseriesWithLocale(locale).pipe(map(({body}) => this.series = body ? body.data.kitseries : null))
        ])
      ).subscribe(([products, kitseries]) => {
        this.products = products;
        this.series = kitseries;
      })
    );

    this.subscriptions.add(
      this.catalogService.getAccessoriesCategories()
        .subscribe(({body}) => this.accessories = body
          ? body.data.accessories_categories.flatMap(cat => {
            return {
              id: '' + cat.id,
              name: cat.name
            };
          }) : null)
    );
  }

  onSubmitForm() {
    const formHelper = new CrmFormHelper();
    formHelper.combinePhoneWithPrefix(this.formGroup, 'phone_prefix', 'phone');

    const productType = this.formGroup.get('model_name').value;
    const companyName = this.formGroup.get('company_name').value;
    const name = this.formGroup.get('name').value;
    const surname = this.formGroup.get('surname').value;
    const email = this.formGroup.get('email').value;
    const phone = this.formGroup.get('phone').value;
    const city = this.formGroup.get('city').value;
    const capCode = this.formGroup.get('cap').value;
    const country = this.formGroup.get('country').value;
    const model = this.formGroup.get('model_slug').value;
    const description = this.formGroup.get('text').value;
    const privacySelected = this.formGroup.get('privacy').value;
    const marketingSelected = this.formGroup.get('marketing').value;

    const dataForCrm = this.crmService.buildQuotationRequestToSend(productType, companyName, name, surname, email,
      phone, city, capCode, country, model, description, privacySelected, marketingSelected);

    this.formService.sendRequest(Forms.QUOTATION, this.formGroup, dataForCrm).subscribe(({body}) => {
      this.consentService.trackConsent('g-quotation-form', 'g-quotation-form-email', 'g-quotation-form-name',
        'g-quotation-form-privacy', 'g-quotation-form-marketing');

      this.alertService.success(body.meta.message);
      this.modalActive.close();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
